























import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import PrintButton from "@/components/Button/PrintButton.vue";
import MailButton from "@/components/Button/MailButton.vue";
import AbortButton from "@/components/Button/AbortButton.vue";
import router from "@/router";
import PaymentDocumentsEmailForm from "@/views/AutomaticPaymentsOverview/PaymentDocumentsEmailForm.vue";
import UserService from "@/services/UserService";

const StudentDocumentModule = namespace("student-document");
const CostBearerModule = namespace("cost-bearer");
const EmailsModule = namespace("emails");

@Component({
  components: { PaymentDocumentsEmailForm, AbortButton, MailButton, FscSimpleCard, PrintButton },
})
export default class InvoicePreviewPdfDocument extends Vue {
  public name = "InvoicePreviewPdfDocument";

  @StudentDocumentModule.Action("findStudentDocumentWithLetterHead")
  public findStudentDocument: any;

  @StudentDocumentModule.Getter("getMimeType")
  public mimeType: any;

  @StudentDocumentModule.Getter("getDataItem")
  public blob: any;

  @StudentDocumentModule.Getter("getIsLoading")
  public isLoading: any;

  @EmailsModule.Action("setStudentInfo")
  public setStudentInfo: any;

  @EmailsModule.Action("setBlobFile")
  public setBlobFile: any;

  @Prop()
  public studentDocumentId!: any;

  @Prop()
  public removeLetterHead!: any;

  @Prop()
  public student!: any;

  @Prop({ type: String })
  public createdByName!: string;

  private source = "";
  private user = this.currentUser || null;

  public emailFormVisible = false;
  public previewFormVisible = false;

  public get currentUser(): any {
    return UserService.getUser() || null;
  }

  public get receiverNameAndEmail() {
    if (!this.student) return null;
    let receiver: any;
    if (this.student.costBearerEmails && this.student.costBearerEmails.length > 0) {
      receiver = {
        name: this.student.costBearers.join(),
        email: this.student.costBearerEmails.join()
      };
    } else {
      receiver = {
        name: this.student?.lastName + " " + this.student?.firstName,
        email: this.student.email
      };
    }
    return receiver;
  }
  public mounted(): void {
    if (this.studentDocumentId) {
      const options = {
        id: this.studentDocumentId,
        removeLetterhead: this.removeLetterHead,
      };
      this.findStudentDocument(options);
      this.previewFormVisible = true;
    }

    this.$emit("on-mounted");
  }

  public onPdfDocumentPrint(): void {
    console.log("on Document print");
  }

  public onPdfDocumentEmail(): void {
    this.setStudentInfo(this.student);
    const options = {
      blob: this.blob ? this.blob : null,
      mimeType: this.mimeType ? this.mimeType : null,
    };
    this.setBlobFile(options);
    this.emailFormVisible = true;
    this.previewFormVisible = false;
  }

  public onEmailAbort() {
    this.emailFormVisible = false;
    this.previewFormVisible = true;
  }

  @Watch("blob")
  public onStudentDocumentChange(blob: any) {
    if (blob) {
      this.source =
        "data:" +
        this.mimeType +
        ";base64," +
        //@ts-ignore
        new Buffer(blob, "binary").toString("base64");
    }
  }

  @Watch("studentDocumentId")
  public onItemChange(id: any): void {
    if (id) {
      const options = {
        id: id,
        removeLetterhead: this.removeLetterHead,
      };
      this.findStudentDocument(options);
    }
  }

  public beforeDestroy() {
    this.setStudentInfo(null);
    const options = {
      blob: null,
      mimeType: null,
    };
    this.setBlobFile(options);
  }
}
